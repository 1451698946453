@tailwind base;

@tailwind components;

@tailwind utilities;

h1.article {
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
}

h2 {
  padding-top: 1em;
  margin-top: 15pt;
  margin-bottom: 8pt;
  font-weight: bold;
}

h2.article {
  font-size: 2rem;
  --tw-text-opacity: 1;
  color: rgba(31,41,55,var(--tw-text-opacity));
}

p.article {
  margin-top: 1em;
}

.gradient {
  background: linear-gradient(90deg, #f1820b 0%, #f9c48b 100%);
}
.button {
  border: 1px solid lightgrey;
  color: black;
}
.importantbutton {
  background-color: #80298a;
  color: #fff;
}

article {
  margin-top: 62pt;
  padding-bottom: 4em;
}

.screenshot {
  margin: 1em auto;
  display: block;
}
